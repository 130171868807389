import { ResponseInterface } from '@app/core/interfaces/response'
import { CoreService } from '@app/core/services/core.service'
import { Observable, map } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'

import { environment } from 'src/environments/environment'

export interface BaseHttpParams {
  take?: number
  skip?: number
  page?: number
  order?: string
  filter?: string
}

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected apiFrontUrl = ''
  protected apiMeUrl = ''
  protected apiAuthUrl = ''
  protected baseUrl = ''
  protected http = inject(HttpClient)
  protected coreService = inject(CoreService)

  constructor() {
    if (this.coreService.isBrowser()) {
      this.apiFrontUrl = environment.api.public + '/front'
      this.baseUrl = environment.api.public
      // this.apiMeUrl = environment.api.public + '/me'
      // this.apiAuthUrl = environment.api.public + '/auth'
    } else {
      this.apiFrontUrl = environment.api.ssr + '/front'
      this.baseUrl = environment.api.ssr
      // this.apiMeUrl = environment.api.ssr + '/me'
      // this.apiAuthUrl = environment.api.ssr + '/auth'
    }
  }

  cacheUid(): number {
    return Date.now()
  }

  protected buildHttpParams(params: BaseHttpParams): HttpParams {
    let httpParams = new HttpParams()

    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        httpParams = httpParams.set(key, String(value))
      }
    })

    return httpParams
  }

  protected getList<T>(endpoint: string, params: BaseHttpParams, mapper: (itemData: T) => T): Observable<ResponseInterface<T>> {
    const httpParams = this.buildHttpParams(params)

    return this.http.get<ResponseInterface<T>>(`${this.baseUrl}${endpoint}`, { params: httpParams }).pipe(
      map((data: ResponseInterface<T>) => ({
        total: data.total,
        data: data.data.map((itemData) => mapper(itemData)),
      })),
    )
  }

  protected getById<T>(endpoint: string, id: number | string, mapper: (itemData: T) => T): Observable<T> {
    return this.http.get<{ item: any }>(`${this.baseUrl}${endpoint}/${id}`).pipe(map((response) => mapper(response.item)))
  }

  protected getPage<T>(endpoint: string, slug: string, mapper: (itemData: T) => T): Observable<T> {
    return this.http.get<{ item: any }>(`${this.baseUrl}${endpoint}/${slug}`).pipe(map((response) => mapper(response.item)))
  }

  protected getStructure(endpoint: string, params: BaseHttpParams): Observable<any> {
    const httpParams = this.buildHttpParams(params)

    return this.http.get(`${this.baseUrl}${endpoint}`, { params: httpParams })
  }
}

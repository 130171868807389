import { isPlatformBrowser } from '@angular/common'
import { Injectable, PLATFORM_ID, inject, signal } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  isBrowser = signal(isPlatformBrowser(inject(PLATFORM_ID)))
  isScrollBlocked = false

  setScrollBlock(state: boolean): void {
    this.isScrollBlocked = state
  }
}
